/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useContext, useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import '../styles/fonts.css'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from "../components/layout"
import Container from "../components/container"
import NewsArticle from "../components/news/news-article"

const newsArticlePage = {
  backgroundColor: 'background',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: ['auto 340px', 'auto 680px', 'auto 920px', 'auto 1152px']
}

const ArticlePage = ({ currentLocale, setCurrentLocale }) => {
  let { articleId } = useParams()
  
  const GET_ARTICLE = gql`
    query GetArticleData($currentLocale: String! $articleId: String!) {
      article(locale: $currentLocale, id: $articleId) {
        sys {
          id
          publishedAt
        }
        coverImage {
          url
        }
        title
        location
        intro {
          json
        }
        body {
          json
          links {
            assets {
              block {
                title
                description
                contentType
                fileName
                size
                url
                width
                height
              }
            }
          }
        }
      }
    }
  `

  const currentSiteAccess = useContext(SiteAccessContext)
  const { data, loading, error } = useQuery(GET_ARTICLE, {
    variables: { articleId, currentLocale },
    fetchPolicy: 'cache-and-network'
  })

  const [article, setArticle] = useState(undefined)

  useEffect(() => {
    if (loading === false && data) {
      setArticle(data.article)
    }
    if (error) console.error(error)
  }, [data, loading, error])

  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout        
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      >
        <Box
          sx={{
            ...newsArticlePage,
            backgroundImage: `linear-gradient(180deg, rgba(0, 22, 39, 0.5) 0%, #001627 35%), url(${article && article.coverImage ? article.coverImage.url : ''})`
          }}
        >
          <Container>
            {article ?
              <NewsArticle
                id={article.sys.id}
                title={article.title}
                location={article.location}
                date={article.sys.publishedAt}
                intro={article.intro ? article.intro.json : null}
                body={article.body}
                article={article}
              />
            : ''}
          </Container>
        </Box>
      </Layout>
    </ThemeProvider>
  )
}

export default ArticlePage