/** @jsx jsx */
import { jsx, NavLink, Text, Image } from 'theme-ui'

import arrowRight from '../../images/arrow-right.svg'
import expand from '../../images/expand-icon.svg'

const UsefulLink = (props) => {
  const externalUrl = props.url ? props.url.includes('http') : ""

  return (
    <NavLink
      href={externalUrl ? props.url : `/learn/${props.articleId}`}
      target={externalUrl ? "_blank" : ""}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '12px 16px',
        marginBottom: '6px',
        backgroundColor: 'header',
        boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px'
      }}
    >
      <Text
        variant="usefulLink"
      >
        {props.title}
      </Text>
      <Image
        sx={{
          height: '15px'
        }}
        src={externalUrl ? expand : arrowRight}
      />
    </NavLink>
  )
}

export default UsefulLink