/** @jsx jsx */
import { jsx, Button, Text, Image } from 'theme-ui'

import arrowDown from '../../images/arrow-down.svg'
import arrowUp from '../../images/arrow-up.svg'
import translate from "../../config/translations.js"

const ShowMore = ({ children, onClick, loading }) => {
  const indexPage = window.location.pathname === '/'

  const showMoreButtonStyles = {
    display: 'flex',
    justifyContent: !children ? 'center' : 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: ['16px 0', '12px 0'],
    marginTop: ['null', indexPage && '16px'],
    border: 'none',
    outline: 'none',
    backgroundColor: ['transparent', indexPage && 'header'],
    color: 'secondary',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 'heading',
    boxShadow: indexPage && '0px 4px 24px 2px rgba(0, 0, 0, 0.4)',
    borderRadius: '8px',
    cursor: 'pointer'
  }

  return (
    <Button
      sx={{
        ...showMoreButtonStyles,
        margin: children ? '0' : 'auto'
      }}
      onClick={onClick}
    >
      <Text>
        { children ? children : loading ? translate('loading') : translate('showMore') }
      </Text>
      <Image
        sx={{
          height: '14px',
          marginLeft: '12px'
        }}
        src={children === translate('readLess') ? arrowUp : arrowDown}
      />
    </Button>
  )
}

export default ShowMore