/** @jsx jsx */
import { jsx, NavLink, Text, Image, Flex } from 'theme-ui'
import PropTypes from "prop-types"

import paperWrite from "../../images/paper-write-icon.svg"
import arrowRight from "../../images/arrow-right.svg"

const featuredCard = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: ['155px', '330px'],
  minWidth: ['155px', '330px'],
  marginRight: ['12px', '24px'],
  padding: ['16px', '32px 24px'],
  boxShadow: '0px 4px 24px 2px rgba(0, 0, 0, 0.4)',
  borderRadius: '8px',
  backgroundColor: 'header',
  backgroundSize: 'cover'
}

const FeaturedCard = (props) => {
  return (
    <NavLink
      href={`/learn/${props.contentId}`}
      sx={{
        ...featuredCard,
        backgroundImage: props.image ? `linear-gradient(180deg, rgba(0, 22, 39, 0.3) 0%, rgba(0, 22, 39, 0.8) 100%), url(${props.image})` : '',
        pointerEvents: props.isDragging ? 'none' : 'auto'
      }}
    >
      <Image
        src={paperWrite}
        sx={{ 
          marginTop: ['null', '166px'],
          marginBottom: ['8px', '13px'],
          height: '24px' 
        }}
      />
      <Flex sx={{ justifyContent: 'space-between', alignContent: 'center', width: '100%' }}>
        <Text
          as="h4"
          variant="cardTitleSmall"
          sx={{
            flex: 10,
            height: ['66px', '45px'],
            overflow: 'hidden',
            fontSize: ['null', '21px']
          }}
        >
          {props.title}
        </Text>
        <Image
          src={arrowRight}
          sx={{
            display: ['none', 'block'],
            flex: 1,
            height: '24px',
            marginLeft: '12px'
          }}
        />
      </Flex>
      <Text
        as="p"
        variant="cardDetails"
        sx={{
          marginTop: '12px',
          height: '18px',
          overflow: 'hidden'
        }}
      >
        {props.category}
      </Text>
    </NavLink>
  )
}

FeaturedCard.propTypes = {
  contentId: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  isDragging: PropTypes.bool
}

export default FeaturedCard