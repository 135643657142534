/** @jsx jsx */
import { jsx, Flex, Text, NavLink, Image} from 'theme-ui'
import PropTypes from "prop-types"

import backButton from "../images/back-button.svg"

const projectsTotalStyles = { 
  marginTop: '10px', 
  marginLeft: '12px', 
  padding: '4px 8px', 
  border: '1px solid', 
  borderColor: 'dullerBlue', 
  borderRadius: '100px', 
  fontSize: '12px'
}

const Heading = (props) => {
  const indexPage = window.location.pathname === "/"
  const learnPage = window.location.pathname === "/learn"
  const projectPage = window.location.pathname === "/projects"
  const categoryPage = window.location.pathname.includes("/category")

  return (
    <Flex
      sx={{
        display: ['flex', (indexPage || learnPage) && 'none'],
        justifyContent: projectPage ? 'space-between' : 'flex-start',
        alignItems: 'center',
        padding: ['16px 0', '32px 0 24px 0']
      }}
    >
      {categoryPage &&
        <NavLink
          href="/learn"
          sx={{ display: 'flex' }}
        >
          <Image
            src={backButton}
            sx={{ marginRight: '24px' }}
          />
        </NavLink>
      }
      <Flex sx={{ alignItems: 'center' }}>
        <Text
          as="h1"
          variant='pageHeading'
          >
          {props.pageTitle}
        </Text>
        {props.projectsTotal && 
          <Text sx={projectsTotalStyles}>
            {props.projectsTotal}
          </Text>
        }
      </Flex>
      {props.children}
    </Flex>
  )
}

Heading.propTypes = {
  pageTitle: PropTypes.string,
  pageCount: PropTypes.number
}

export default Heading