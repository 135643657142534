// theme styles

export const theme = {
  Mongolia: {
    breakpoints: ['768px', '1024px', '1300px'],
    fonts: {
      body: 'Poppins, sans-serif'
    },
    fontSizes: ['13px', '14px', '16px', '18px', '21px', '24px'],
    fontWeights: {
      body: 400,
      detail: 500,
      heading: 600,
      bold: 700,
      black: 800,
    },
    colors: {
      text: '#FFFFFF',
      background: '#001627',
      header: '#13293A',
      headerBorder: '#DA2032',
      activeNav: '#FFDE00',
      primary: '#1F3545',
      secondary: '#E2F3FF',
      royalBlue: '#0066B3',
      dullBlue: '#A0C4E0',
      dullerBlue: '#486D8A',
      blue5: '#A5BCCD',
      arsenic: '#E9776F',
      copper: '#FF9900',
      coal: '#A7A7A7',
      cyanide: '#97D999',
      diamond: '#DEDEDE',
      flurospar: '#D086D1',
      gold: '#FFDE00',
      iron: '#E0B6B6',
      uranium: '#A0C4E0',
      blockMouseover: '#2B4B63'
    },
    styles: {
      root: {
        fontFamily: 'body',
        fontWeight: 'body',
        color: 'text',
        backgroundColor: 'background'
      }
    },
    images: {
      nav: {
        width: '24px',
        height: '24px'
      }
    },
    text: {
      pageHeading: {
        fontFamily: 'body',
        fontSize: '24px',
        fontWeight: 'heading',
        color: 'text',
      },
      nav: {
        fontSize: ['13px', '13px', '16px'],
        fontWeight: 'heading',
        color: 'dullBlue'
      },
      activeNav: {
        fontSize: ['13px', '13px', '16px'],
        fontWeight: 'heading',
        color: 'activeNav'
      },
      cardTitle: {
        fontSize: '21px',
        fontWeight: 'heading',
        color: 'white'
      },
      cardTitleSmall: {
        fontSize: '16px',
        fontWeight: 'heading',
        lineHeight: '22px',
        color: 'white'
      },
      cardDetails: {
        fontSize: '14px',
        fontWeight: 'detail',
        lineHeight: '18px',
        color: 'secondary'
      },
      cardDetailsSmall: {
        fontSize: '14px',
        fontWeight: 'body',
        color: 'dullBlue'
      },
      intro: {
        fontSize: '16px',
        fontWeight: 'body',
        lineHeight: '24px',
        color: 'secondary'
      },
      usefulLink: {
        fontSize: '16px',
        fontWeight: 'body',
        color: 'text'
      },
      footer: {
        fontSize: '16px',
        fontWeight: 'body',
        color: 'dullBlue'
      }
    }
  },
  // styling is currently set to the same as mongolia. will amend once we get the designs for kyrgystan
  Kyrgyzstan: {
    breakpoints: ['768px', '1024px', '1300px'],
    fonts: {
      body: 'Poppins, sans-serif'
    },
    fontSizes: ['13px', '14px', '16px', '18px', '21px', '24px'],
    fontWeights: {
      body: 400,
      detail: 500,
      heading: 600,
      bold: 700,
      black: 800,
    },
    colors: {
      text: '#FFFFFF',
      background: '#001627',
      header: '#13293A',
      headerBorder: '#0066A1',
      activeNav: '#FDBC2D',
      primary: '#1F3545',
      secondary: '#E2F3FF',
      royalBlue: '#0066A1',
      dullBlue: '#A0C4E0',
      dullerBlue: '#486D8A',
      blue5: '#A5BCCD',
      arsenic: '#E9776F',
      copper: '#FF9900',
      coal: '#A7A7A7',
      cyanide: '#97D999',
      diamond: '#DEDEDE',
      flurospar: '#D086D1',
      gold: '#FFDE00',
      iron: '#E0B6B6',
      uranium: '#A0C4E0',
      blockMouseover: '#2B4B63'
    },
    styles: {
      root: {
        fontFamily: 'body',
        fontWeight: 'body',
        color: 'text',
        backgroundColor: 'background'
      }
    },
    images: {
      nav: {
        width: '24px',
        height: '24px'
      }
    },
    text: {
      pageHeading: {
        fontFamily: 'body',
        fontSize: '24px',
        fontWeight: 'heading',
        color: 'text',
      },
      nav: {
        fontSize: ['13px', '13px', '16px'],
        fontWeight: 'heading',
        color: 'dullBlue'
      },
      activeNav: {
        fontSize: ['13px', '13px', '16px'],
        fontWeight: 'heading',
        color: 'activeNav'
      },
      cardTitle: {
        fontSize: '21px',
        fontWeight: 'heading',
        color: 'white'
      },
      cardTitleSmall: {
        fontSize: '16px',
        fontWeight: 'heading',
        lineHeight: '22px',
        color: 'white'
      },
      cardDetails: {
        fontSize: '14px',
        fontWeight: 'detail',
        lineHeight: '18px',
        color: 'secondary'
      },
      cardDetailsSmall: {
        fontSize: '14px',
        fontWeight: 'body',
        color: 'dullBlue'
      },
      intro: {
        fontSize: '16px',
        fontWeight: 'body',
        lineHeight: '24px',
        color: 'secondary'
      },
      usefulLink: {
        fontSize: '16px',
        fontWeight: 'body',
        color: 'text'
      },
      footer: {
        fontSize: '16px',
        fontWeight: 'body',
        color: 'dullBlue'
      }
    }
  }
}