/** @jsx jsx */
import { jsx, Flex, Box, Button, Image, Text, Label, Checkbox } from 'theme-ui'
import PropTypes from "prop-types"
import React from "react"

import refreshArrow from "../../images/refresh-arrow.svg"
import translate from "../../config/translations.js"

const projectFilterMenuStyles = {
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 1,
  width: '295px',
  height: '100%',
  top: 0,
  right: 0,
  padding: '16px 24px',
  backgroundColor: 'header',
  boxShadow: '0px 4px 24px 2px rgba(0, 0, 0, 0.6)'
}

const applyFilterButtonStyles = {
  width: '100%',
  borderRadius: 0,
  backgroundColor: 'royalBlue',
  color: 'text',
  boxShadow: '0px 4px 24px 2px rgba(0, 0, 0, 0.6)',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 'heading'
}

const resetButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0 25px 0',
  padding: '0',
  backgroundColor: 'transparent',
  color: 'secondary',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 'heading'
}

const filterOptionTextStyles = {
  display: 'inline-block',
  textAlign: 'center',
  padding: '2px 0',
  width: '40px',
  margin: 'auto',
  border: '1px solid #486D8A',
  borderRadius: '100px',
  color: 'dullBlue',
  fontSize: '12px',
  fontWeight: 'detail'
} 

const ProjectFilterOptions = ({
  selectedProducts,
  setSelectedProducts,
  selectedLocations,
  setSelectedLocations,
  setProjectFilterOpen,
  resetFilters,
  setProductFilters,
  setLocationFilters,
  predefinedProducts,
  predefinedLocations,
  setFiltersSet
}) => {
  const handleSelectedProduct = (e) => {
    let selectedProduct = e
    if (!selectedProducts.includes(e)) {
      setSelectedProducts([...selectedProducts, e])
    }
    else {
      setSelectedProducts(selectedProducts.filter((e) => (e !== selectedProduct)))
    }
  }

  const handleSelectedLocation = (e) => {
    let selectedLocation = e
    if (!selectedLocations.includes(e)) {
      setSelectedLocations([...selectedLocations, e])
    }
    else {
      setSelectedLocations(selectedLocations.filter((e) => (e !== selectedLocation)))
    }
  }

  const applyFilters = (e) => {
    e.preventDefault()

    if ((selectedProducts.length + selectedLocations.length) !== 0 ) {
      setProductFilters(selectedProducts)
      setLocationFilters(selectedLocations)
      setFiltersSet(true)
      setProjectFilterOpen(false)
    }
  }

  return (
    <React.Fragment>
      <Box
        as="form"
        sx={projectFilterMenuStyles}
        onSubmit={applyFilters}
      >
        <Button
          type="submit"
          sx={applyFilterButtonStyles}
        >
          { translate('applyFilters') }
        </Button>
        <Button
          sx={resetButtonStyles}
          onClick={resetFilters}
        >
          <Image src={refreshArrow} />
          <Text sx={{ marginLeft: '12px' }}>
            { translate('reset') }
          </Text>
        </Button>

        <Text sx={{ color: 'secondary' }}>
          { translate('products') }
        </Text>
        {Object.entries(predefinedProducts).map(([key, value]) =>
          <Flex sx={{ margin: '18px 8px 0 8px' }} key={key}>
            <Label sx={{ color: 'dullBlue' }}>
              <Checkbox
                checked={selectedProducts.includes(key) ? true : false}
                sx={{
                  marginRight: '16px',
                  color: 'dullBlue'
                }}
                onChange={() => handleSelectedProduct(key)}
              />
              { translate(key) }
            </Label>
            <Text sx={filterOptionTextStyles}>
              {value.length}
            </Text>
          </Flex>
        )}

        <Text
          sx={{
            marginTop: '28px',
            color: 'secondary'
          }}
        >
          { translate('provinces') }
        </Text>
        {Object.entries(predefinedLocations).map(([key, value]) =>
          <Flex
            sx={{ margin: '18px 8px 0 8px' }}
            key={key}
          >
            <Label sx={{ color: 'dullBlue' }}>
              <Checkbox
                checked={selectedLocations.includes(key) ? true : false}
                sx={{
                  marginRight: '16px',
                  color: 'dullBlue'
                }}
                onChange={() => handleSelectedLocation(key)}
              />
              {key}
            </Label>
            <Text sx={filterOptionTextStyles}>
              {value.length}
            </Text>
          </Flex>
        )}
      </Box>
    </React.Fragment>
  )
}

ProjectFilterOptions.propTypes = {
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  selectedLocations: PropTypes.array,
  setSelectedLocations: PropTypes.func,
  setProjectFilterOpen: PropTypes.func,
  resetFilters: PropTypes.func,
  setProductFilters: PropTypes.func,
  setLocationFilters: PropTypes.func,
  predefinedProducts: PropTypes.object,
  predefinedLocations: PropTypes.object,
  setFiltersSet: PropTypes.func
}

export default ProjectFilterOptions