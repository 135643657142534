/** @jsx jsx */
import { jsx, Box, Button, Text, Image } from 'theme-ui'
import PropTypes from "prop-types"
import React from "react"
import refreshArrow from "../../images/refresh-arrow.svg"
import translate from "../../config/translations.js"

const filteredProjectResultsStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  margin: '4px 0 16px 0',
  background: 'rgba(12, 85, 140, 0.3)',
  border: '2px solid #0C558C',
  boxSizing: 'border-box',
  borderRadius: '8px',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 'heading'
}

const ProjectFilterResultsCount = ({ projects, allProjects, resetFilters, filtersSet, productFilters, locationFilters}) => {
  return (
    <React.Fragment>
      <Button
        onClick={() => resetFilters()}
        sx={{
          ...filteredProjectResultsStyles,
          display: filtersSet ? 'flex' : 'none'
        }}
      >
        <Text>
          {(locationFilters === 0 && productFilters.length === 0) && allProjects ? allProjects.length
          : (locationFilters !== 0 || productFilters.length !== 0) && projects ? projects.length
          : ''} { translate('currentlyShowing') }
        </Text>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Image src={refreshArrow} />
          <Text sx={{ marginLeft: '8px'}}>
            { translate('reset') }
          </Text>
        </Box>
      </Button>
    </React.Fragment>
  )
}

ProjectFilterResultsCount.propTypes = {
  projects: PropTypes.array,
  resetFilters: PropTypes.func
}

export default ProjectFilterResultsCount