/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useState, useEffect } from 'react'

import Dot from '../ui/dot'

const scrollIndicatorStyles = {
  display: ['none', 'flex'],
  justifyContent: 'center',
  margin: '32px 0 6px 0'
}

const ScrollIndicator = ({ target, count }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const scrollListener = () => {
    if (!target.current) {
      return
    }
  
    const element = target.current
    const windowScroll = element.scrollLeft
    const totalWidth = element.scrollWidth - element.clientWidth

    if (windowScroll === 0) {
      return setScrollProgress(0)
    }
  
    if (windowScroll > totalWidth) {
      return setScrollProgress(100)
    }
  
    setScrollProgress((windowScroll / totalWidth) * 100)
  }

  useEffect(() => {
    const element = target.current
    element.addEventListener('touchmove', scrollListener)
    element.addEventListener('mousemove', scrollListener)
    return () => {
      element && element.removeEventListener('touchmove', scrollListener)
      element.removeEventListener('mousemove', scrollListener)
    }
  })

  const renderDots = () => {
    const selectedDotValue = (scrollProgress * count) / 100
    return [ ...Array(count).keys() ].map( index => (
      <Dot 
        key={index}
        active={selectedDotValue >= index && selectedDotValue <= index + 1}
      />
    ))
  }

  return (
    <Box sx={scrollIndicatorStyles}>
      {renderDots()}
    </Box>
  )
}

export default ScrollIndicator