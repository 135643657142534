/** @jsx jsx */
import { jsx, Text, Box } from 'theme-ui'
// eslint-disable-next-line
import React, { useContext, useState, useEffect, useRef } from "react"
import { useQuery, gql } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import '../styles/fonts.css'
import translate from "../config/translations.js"

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from "../components/layout"
import Container from "../components/container"
import Heading from '../components/heading'
import CategoryCard from "../components/learn/category-card"
import FeaturedCard from "../components/learn/featured-card"
import ScrollIndicator from "../components/ui/scroll-indicator"

const LearnPage = ({ currentLocale, setCurrentLocale }) => {
  // Target ref for carousel scroll indicator
  const target = useRef(null)

  // Get categories for Learn page
	const GET_PAGE = gql`
		query GetPages($currentSiteAccess: String!, $currentLocale: String!) {
			pageCollection(locale: $currentLocale, where: {
				site: {
						title: $currentSiteAccess
				}
				title: "Learn"
			}) {
				items {
						title
						componentsCollection {
							items {
								... on ComponentsCategory {
									sys {
										id
									}
									name
								}
							}
						}
				}
			}
		}
	`

  // Get featured articles and article count per category
	const GET_ARTICLES = gql`
		query GetArticles($currentLocale: String!, $pageCategories: [String!]!) {
			articleCollection(
				locale: $currentLocale, 
				order: priority_ASC, 
				where:{
					AND: [
						{ featured_exists: true },
            { 
              category: {
                sys: {
                  id_in: $pageCategories
                }
              }
            }
					]
				}) { 
					items {
						sys {
						  id
						}
						title
						location
						featured
						coverImage {
						  title
						  url
						}
						category {
						  name
						}
					}
				}
				componentsCategoryCollection(locale: "en-US", order: name_ASC, where: {
					sys: { 
						id_in: $pageCategories
					}
				}) {
					items {
						sys {
							id
						}
						linkedFrom {
							articleCollection {
								total
							}
						}
					}
				}
			}
    `

  const currentSiteAccess = useContext(SiteAccessContext)

  const [articles, setArticles] = useState(undefined)
  const [categories, setCategories] = useState(undefined)
  const [categoryStats, setCategoryStats] = useState(undefined)
  const [isDragging, setIsDragging] = useState(false)

  const { data: pageData, loading: loadingPage, error: pageQueryError } = useQuery(GET_PAGE, {
    variables: { currentSiteAccess, currentLocale },
    fetchPolicy: 'cache-and-network'
  })

  // Return IDs of categories
  const categoriesReady = () => {
    return pageData &&
    pageData.pageCollection &&
    pageData.pageCollection.items[0] &&
    pageData.pageCollection.items[0].componentsCollection.items
			? pageData.pageCollection.items[0].componentsCollection.items.map(item => item.sys.id)
			: null
  }

  // Callback for fitler to check if category has articles associated
  const checkArticleCount = (c) => {
    return categoryStats[c.sys.id] > 0
  }

  const { data: articleData, loading, error } = useQuery(GET_ARTICLES, {
    skip: !pageData,
    variables: {
        currentLocale,
        pageCategories: categoriesReady()
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    let x = 0, left = 0

    if (target.current) {
      let draggingFunction = (e) => {
        setIsDragging(true)
        document.addEventListener('mouseup', (e) => {
          document.removeEventListener("mousemove", draggingFunction)
          setIsDragging(false)
        })

        target.current.scrollLeft = left - e.pageX + x
      }

      target.current.addEventListener('mousedown', (e) => {
        e.preventDefault()

        x = e.pageX
        left = target.current.scrollLeft

        document.addEventListener('mousemove', draggingFunction)
      })  
    }    
  })

  useEffect(() => {
		if (loadingPage === false && loading === false && articleData) {
      let categoriesStats = articleData.componentsCategoryCollection.items

      // Create flat ["id" => totalCount] array
      let totals = []
      categoriesStats.forEach(i => {
        totals[i.sys.id] = i.linkedFrom.articleCollection.total
      })
      setCategoryStats(totals)

      // Set data to corresponding states
	    setCategories(pageData.pageCollection.items[0].componentsCollection.items)
      setArticles(articleData.articleCollection.items)
		}
    if (error) console.error(error)
    if (pageQueryError) console.error(pageQueryError)
    // eslint-disable-next-line
  }, [articleData, loading, loadingPage, pageQueryError, error])

  const scrollIndicatorCount = typeof articles !== 'undefined' ? Math.round(articles.length / 3) : 0

  if (loadingPage) return null
  if (loading) return null

  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout
          currentLocale={currentLocale}
          setCurrentLocale={setCurrentLocale}
      >
        <Container>
          <Heading pageTitle={ translate('learn') } />
          <Text
            as="h2"
            variant="cardTitle"
            sx={{
              display: ['block', 'none'],
              marginTop: '8px'
            }}
          >
            { translate('featured') }
          </Text>
          <Box
            ref={target}
            sx={{
                display: 'flex',
                overflow: 'auto',
                width: '100%',
                marginTop: ['16px', '48px'],
                '::-webkit-scrollbar': {
                  display: 'none'
                }
            }}
          >
            {articles && articles.map((article, key) => (article.featured &&
              <FeaturedCard
                key={key}
                contentId={article.sys.id}
                slug={article._url}
                title={article.title}
                image={article.coverImage ? article.coverImage.url : ""}
                category={article.category ? article.category.name : ''}
                isDragging={isDragging}
              />
            ))}
          </Box>
          { scrollIndicatorCount > 1 ? (
              <ScrollIndicator target={target} count={scrollIndicatorCount} />
            ) : ''}
          <Text
            as="h2"
            variant="cardTitle"
            sx={{ 
              marginTop: ['26px', '32px'], 
              marginBottom: '16px', 
              fontSize: ['null', '28px'] 
            }}
          >
            { translate('categories') }
          </Text>
          {categories ? categories.filter(checkArticleCount).map((category, key) =>
            <CategoryCard
              key={key}
              name={category.name}
              categoryId={category.sys.id}
              quantity={categoryStats[category.sys.id]}
            />
          ) : ''}
          <Box
            sx={{ marginTop: '16px' }}
          >
          </Box>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default LearnPage
 