import React from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import NavMenu from "./nav/nav-menu"

import translate from '../config/translations'

const Layout = ({ currentLocale, setCurrentLocale, children }) => {
  return (
    <React.Fragment>
      <Header
        siteTitle={ translate('siteTitle') }
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      />
      {children}
      <NavMenu />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
