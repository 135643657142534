module.exports = {
  'domains': {
    'mongolia': [
      'localhost.com',
      'dev.mergenboodii.mn',
      'prod.mergenboodii.mn',
      'www.mergenboodii.mn',
      'mergenboodii.mn'
    ],
    
    'kyrgyzstan': [
      'localhost.ky',
      'dev.kender.kg',
      'prod.kender.kg',
      'www.kender.kg',
      'kender.kg'
    ]
  }
}