/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

import Footer from './footer'

const Container = ({ children }) => {
  return (
    <Box
      as="main"
      sx={{
        maxWidth: ['340px', '680px', '920px', '1152px'],
        paddingBottom: '68px',
        margin: '0 auto'
      }}
    >
      {children}
      <Footer />
    </Box>
  )
}

export default Container