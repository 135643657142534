import Cookies from 'universal-cookie'
import contentfulLink from '../components/ui/contentful-link'
import React from 'react'
import { SiteAccessContext } from '../components/SiteAccessContext'
import { languages } from './languages'
import { useContext } from 'react'

const vocabulary = {
  catchphrase: {
    'en-US': 'Catchphrase text catchphrase text',
    'mn-MNG': 'Нийтлэг үг хэллэг',
    'ky-KG': 'Көңүл буруу фразасы',
    'ru-RU': 'Кетч-фразы'
  },
  latestNews: {
    'en-US': 'Latest news',
    'mn-MNG': 'Сүүлийн үеийн мэдээ',
    'ky-KG': 'Акыркы жаңылыктар',
    'ru-RU': 'Последние новости'
  },
  siteTitle: {
    'en-US': 'Inform',
    'mn-MNG': 'Мэдээлэх',
    'ky-KG': 'Маалымдоо',
    'ru-RU': 'Сообщить',
  },
  showMore: {
    'en-US': 'Show More',
    'mn-MNG': 'Дэлгэрэнгүй харуулах',
    'ky-KG': 'Кошумча маалымат',
    'ru-RU': 'Показать больше',
  },
  news: {
    'en-US': 'News',
    'mn-MNG': 'Мэдээ',
    'ky-KG': 'Жаңылыктар',
    'ru-RU': 'Новости',
  },
  projects: {
    'en-US': 'Projects',
    'mn-MNG': 'Төслүүдийн тухай',
    'ky-KG': 'Долбоорлор',
    'ru-RU': 'Проекты',
  },
  learn: {
    'en-US': 'Learn',
    'mn-MNG': 'Мэдлэгийн булан',
    'ky-KG': 'Билип алыңыз',
    'ru-RU': 'Узнать',
  },
  more: {
    'en-US': 'More',
    'mn-MNG': 'Дэлгэрэнгүй',
    'ky-KG': 'Кошумча',
    'ru-RU': 'Подробнее',
  },
  filters: {
    'en-US': 'Filters',
    'mn-MNG': 'Шүүлтүүрүүд',
    'ky-KG': 'Фильтр',
    'ru-RU': 'Фильтры',
  },
  applyFilters: {
    'en-US': 'Apply filters',
    'mn-MNG': 'Шүүлтүүр хэрэглэх',
    'ky-KG': 'Фильтр колдонуу',
    'ru-RU': 'Применить фильтры',
  },
  reset: {
    'en-US': 'Reset',
    'mn-MNG': 'Шинээр эхлэх',
    'ky-KG': 'Жаңылоо',
    'ru-RU': 'Перезагрузить',
  },
  products: {
    'en-US': 'Products',
    'mn-MNG': 'Бүтээгдэхүүн',
    'ky-KG': 'Ишмердүүлүк',
    'ru-RU': 'Продукты',
  },
  provinces: {
    'en-US': 'Provinces',
    'mn-MNG': 'Аймаг',
    'ky-KG': 'Аймактар',
    'ru-RU': 'Области',
  },
  currentlyShowing: {
    'en-US': 'currently showing',
    'mn-MNG': 'одоогоор харуулж байна',
    'ky-KG': 'азыр көрсөтүлүүдө',
    'ru-RU': 'Показывает сейчас',
  },
  featured: {
    'en-US': 'Featured',
    'mn-MNG': 'Онцлох',
    'ky-KG': 'Атайын тандалган',
    'ru-RU': 'Избранный',
  },
  categories: {
    'en-US': 'Categories',
    'mn-MNG': 'Ангилалууд',
    'ky-KG': 'Категориялар',
    'ru-RU': 'Категории',
  },
  articles: {
    'en-US': 'articles',
    'mn-MNG': 'Нийтлэлүүд',
    'ky-KG': 'макалалар',
    'ru-RU': 'Статьи',
  },
  loading: {
    'en-US': 'Loading',
    'mn-MNG': 'Ачаалж байна',
    'ky-KG': 'Жүктөө',
    'ru-RU': 'Загружается',
  },
  updated: {
    'en-US': 'Updated',
    'mn-MNG': 'Шинэчлэгдсэн',
    'ky-KG': 'Жаңыланды',
    'ru-RU': 'Обновлено',
  },
  months: {
    'en-US': ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    'mn-MNG': ["1-p сар", "2-p сар", "3-p сар", "4-p сар", "5-p сар", "6-p сар", "7-p сар", "8-p сар", "9-p сар", "10-p сар", "11-p сар", "12-p сар"],
    'ky-KG': ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    'ru-RU': ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
  },
  description: {
    'en-US': 'Description',
    'mn-MNG': 'Тайлбар',
    'ky-KG': 'Кеңири маалымат',
    'ru-RU': 'Описание',
  },
  readMore: {
    'en-US': 'Read more',
    'mn-MNG': 'Цааш унших',
    'ky-KG': 'Баарын көрүү',
    'ru-RU': 'Читать больше',
  },
  readLess: {
    'en-US': 'Read less',
    'mn-MNG': 'Хураангуйлах',
    'ky-KG': 'Жабуу',
    'ru-RU': 'Читать меньше',
  },
  mineWebsite: {
    'en-US': 'Mine website',
    'mn-MNG': 'Уурхайн вэбсайт',
    'ky-KG': 'Кен байлыктын баракчасы',
    'ru-RU': 'Сайт месторождения ',
  },
  mineDisclaimer: {
    'en-US': 'We are not responsible for the content of external websites',
    'mn-MNG': 'Бид гадны вэбсайт дээр нийтлэгдсэн аливаа контентуудын өмнө хариуцлага хүлээхгүй болно.',
    'ky-KG': 'Сырткы интернет баракчанын мазмунуна жооптуу эмеспиз',
    'ru-RU': 'Мы не несем ответственности за содержание внешних веб-сайтов',
  },
  usefulLinks: {
    'en-US': 'Useful Links',
    'mn-MNG': 'Хэрэгтэй холбоосууд',
    'ky-KG': 'Пайдалуу шилтемелер',
    'ru-RU': 'Полезные ссылки',
  },
  Arsenic: {
    'en-US': 'Arsenic',
    'mn-MNG': 'Хүнцэл',
    'ky-KG': 'Мышьяк',
    'ru-RU': 'Мышьяк',
  },
  Copper: {
    'en-US': 'Copper',
    'mn-MNG': 'Зэс',
    'ky-KG': 'Жез',
    'ru-RU': 'Медь',
  },
  Coal: {
    'en-US': 'Coal',
    'mn-MNG': 'Нүүрс',
    'ky-KG': 'Көмүр',
    'ru-RU': 'Каменный уголь',
  },
  Cyanide: {
    'en-US': 'Cyanide',
    'mn-MNG': 'Цианид',
    'ky-KG': 'Цианид',
    'ru-RU': 'Цианид',
  },
  Flurospar: {
    'en-US': 'Flurospar',
    'mn-MNG': 'Жонш',
    'ky-KG': 'Флюорит',
    'ru-RU': 'Плавиковый шпат',
  },
  Gold: {
    'en-US': 'Gold',
    'mn-MNG': 'Алт',
    'ky-KG': 'Алтын',
    'ru-RU': 'Золото',
  },
  Iron: {
    'en-US': 'Iron',
    'mn-MNG': 'Төмөр',
    'ky-KG': 'Темир',
    'ru-RU': 'Железо',
  },
  projectCtaTitle: {
    'en-US': 'Discover new projects',
    'mn-MNG': 'Шинэ төслүүд хайх',
    'ky-KG': 'Жаңы долбоорлор',
    'ru-RU': 'Узнайте о новых проектах',
  },
  projectCtaDescription: {
    'en-US': 'Learn about the mining projects going on in and around Mongolia',
    'mn-MNG': 'МУ-д болон ойролцоо үйл ажиллагаа явуулж буй уул уурхайн төслүүдийн талаар олж мэдэх',
    'ky-KG': 'Монголия жана анын айланасындагы тоо-кен долбоорлор тууралуу маалымат алыңыз',
    'ru-RU': 'Узнайте о горнодобывающих проектах в Монголии',
  },
  learnCtaTitle: {
    'en-US': 'Become an informed citizen',
    'mn-MNG': 'Мэдээлэлтэй иргэн болох',
    'ky-KG': 'Маалыматы бар жаран болуңуз',
    'ru-RU': 'Станьте информированным гражданином',
  },
  learnCtaDescription: {
    'en-US': 'Read up on the mining process, materials, laws, environment and your rights',
    'mn-MNG': 'Уул уурхайн үйл явц, холбоотой материалууд, хууль тогтоомж, хүрээлэн буй орчин, болон эрхийнхээ талаар уншина уу',
    'ky-KG': 'Кендин өндүрүштүк цикли, макалалар, мыйзамдар, айлана-чөйрө жана сиздин укуктарыңыз тууралуу маалымат алыңыз',
    'ru-RU': 'Читайте о процессе добычи, материалах, законодательстве, окружающей среде и ваших правах',
  },
  footerCopyright: {
    'en-US': 'Copyright 2021 © Inform',
    'mn-MNG': '© 2021 - Информ компани. Бүх эрх хуулиар хамгаалагдсан.',
    'ky-KG': 'Автордук укук корголгон 2021 © Inform',
    'ru-RU': 'Автордук укук корголгон 2021 © Inform',
  },
  footerOxford: {
    'en-US': 'A project under the Oxford group',
    'mn-MNG': 'Оксфорд группын төсөл',
    'ky-KG': 'Оксфорд тобунун алдындагы долбоор',
    'ru-RU': 'Оксфорд тобунун алдындагы долбоор',
  },
  footerPoweredBy: {
    'en-US': (<>Powered by {contentfulLink} </>),
    'mn-MNG': (<>{contentfulLink} платформыг ашиглан хөгжүүлэв </>),
    'ky-KG': (<>{contentfulLink} тарабынан иштелип чыккан </>),
    'ru-RU': (<> {contentfulLink} тарабынан иштелип чыккан </>),
  }
}

const Translate = (key) => {
  const currentSiteAccess = useContext(SiteAccessContext)

  // Get locale
  const cookies = new Cookies()
  const locale = cookies.get('siteLocale') ? cookies.get('siteLocale') : languages[currentSiteAccess][Object.keys(languages[currentSiteAccess])[0]]

  if (!vocabulary[key]) {
    return key
  } else if (!vocabulary[key][locale]) {
    return vocabulary[key]['en-US']
  } else {
    return vocabulary[key][locale]
  }
}

export default Translate