/** @jsx jsx */
import { jsx, NavLink, Text, Image, Flex, Button } from 'theme-ui'
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { SiteAccessContext } from './SiteAccessContext'

import mongoliaFlag from "../images/mongolian-flag.svg"
import kyrgystanFlag from "../images/kyrgystan-flag.svg"
import arrowRight from "../images/arrow-right.svg"
import arrowRightCta from "../images/arrow-right-cta.svg"

const catchphraseStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: ['12px 0', '32px 0'],
  borderBottom: '2px solid',
  borderBottomColor: 'header',
  'a:hover': {
    color: 'text'
  }
}

const catchphraseTextStyles = {
  fontSize: ['14px', '18px'],
  fontWeight: 'detail',
  color: 'secondary',
  paddingLeft: ['24px', '32px'],
  marginTop: ['8px', '0']
}

const readMoreButton = {
  display: ['none', 'flex'],
  alignItems: 'center',
  marginLeft: '32px',
  marginRight: ['null', 'null', '80px'],
  padding: '12px 16px 12px 24px',
  color: 'text',
  backgroundColor: 'royalBlue',
  boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.3)',
  borderRadius: '0',
  fontFamily: 'body',
  lineHeight: '23px',
  textDecoration: 'none',
  cursor: 'pointer'
}

const Catchphrase = ({ catchphrase, readMore }) => {
  const currentSiteAccess = useContext(SiteAccessContext)

  return (
    <React.Fragment>
      <NavLink
        href={`/about`}
        sx={catchphraseStyles}
        disabled='disabled'
      >
        <Flex
          sx={{
            flex: [5, 10],
            justifyContent: 'space-between'
          }}
        >
          <Image
            src={currentSiteAccess === `Kyrgyzstan` ?  kyrgystanFlag : mongoliaFlag}
            sx={{ flex: [1, 1, 0.7, 0.5] }}
          />
          <Flex
            sx={{
              flex: 8,
              flexDirection: 'column'
            }}
          >
            <Text
              sx={catchphraseTextStyles}
            >
              {catchphrase}
            </Text>
          </Flex>
        </Flex>
        <Image
          sx={{
            display: ['block', 'none'],
            flex: [0.25, 0.5],
            marginLeft: '26px'
          }}
          src={arrowRight}
        />
        <Button
          sx={readMoreButton}
        >
          <Text
            sx={{
              marginRight: '12px'
            }}
          >
            {readMore}
          </Text>
          <Image
            src={arrowRightCta}
          />
        </Button>
      </NavLink>
    </React.Fragment>
  )
}

Catchphrase.propTypes = {
  catchphrase: PropTypes.string,
  readMore: PropTypes.string,
}

export default Catchphrase