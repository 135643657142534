module.exports = {
  'languages': {
    'Mongolia': {
      'Монгол': 'mn-MNG',
    },
    'Kyrgyzstan': {
      'Кыргызча': 'ky-KG',
      'Pусский': 'ru-RU',
    }
  }
}