/** @jsx jsx */
import { jsx, Button, Image, Text, Box } from 'theme-ui'
import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import ProjectFilterOptions from "./project-filter-options"
import filterIcon from "../../images/filter-icon.svg"
import translate from "../../config/translations.js"

const filterButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  height: '42px',
  padding: '12px 16px',
  backgroundColor: 'header',
  boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.6)',
  borderRadius: '8px'
}

const filterButtonTextStyles = {
  marginLeft: '12px',
  color: 'secondary',
  fontSize: '14px',
  fontWeight: 'heading'
}

const filterButtonCountStyles = {
  marginLeft: '8px',
  padding: '4px 8px',
  border: '1px solid',
  borderColor: 'dullerBlue',
  boxSizing: 'border-box',
  borderRadius: '100px',
  color: 'secondary',
  fontSize: '12px',
  fontWeight: 'heading'
}

const ProjectFilterSelector = ({ 
  selectedProducts, 
  setSelectedProducts, 
  selectedLocations, 
  setSelectedLocations, 
  resetFilters, 
  setProductFilters, 
  setLocationFilters, 
  predefinedProducts, 
  predefinedLocations, 
  setFiltersSet
}) => {
  const [projectFilterOpen, setProjectFilterOpen] = useState(false)
  const menuRef = useRef(null);

  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setProjectFilterOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    }
  })

  return (
    <React.Fragment>
      <Box>
        <Button
          onClick={() => setProjectFilterOpen(true)}
          sx={filterButtonStyles}
        >
          <Image src={filterIcon}/>
          <Text sx={filterButtonTextStyles}>
            { translate('filters') }
          </Text>
          {selectedProducts.length + selectedLocations.length !== 0 ?
            <Text sx={filterButtonCountStyles}>
              {selectedProducts.length + selectedLocations.length}
            </Text>
          : ''}
        </Button>
        <Box
          ref={menuRef}>
          {projectFilterOpen ?
            <ProjectFilterOptions
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              setProjectFilterOpen={setProjectFilterOpen}
              resetFilters={resetFilters}
              setProductFilters={setProductFilters}
              setLocationFilters={setLocationFilters}
              predefinedProducts={predefinedProducts}
              predefinedLocations={predefinedLocations}
              setFiltersSet={setFiltersSet}
            />
          : ''}
        </Box>
      </Box>
    </React.Fragment>
  )
}

ProjectFilterSelector.propTypes = {
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  selectedLocations: PropTypes.array,
  setSelectedLocations: PropTypes.func,
  resetFilters: PropTypes.func,
  setProductFilters: PropTypes.func,
  setLocationFilters: PropTypes.func,
  predefinedProducts: PropTypes.object,
  predefinedLocations: PropTypes.object,
  setFiltersSet: PropTypes.func
}

export default ProjectFilterSelector