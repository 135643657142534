import React, { useContext, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import {createHttpLink, ApolloProvider} from '@apollo/client'
import getApolloClient from './client/graphql'
import { setContext } from '@apollo/client/link/context'
import * as serviceWorker from './serviceWorkerRegistration'
import Cookies from 'universal-cookie'

import { SiteAccessContext } from './components/SiteAccessContext'
import { languages } from './config/languages'

import IndexPage from './pages/index'
import ProjectsPage from './pages/projects'
import LearnPage from './pages/learn'
import MorePage from './pages/more'
import AboutPage from './pages/about'
import ArticlePage from './pages/article'
import ProjectPage from './pages/project'
import CategoryPage from './pages/category'
import NotFoundPage from './pages/404'

const App = () => {
  // Configure apollo client bootstrapping
  const SPACE = process.env.REACT_APP_CONTENTFUL_SPACE_ID
  const TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
  const ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
  const CONTENTFUL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}`

  const [client, setClient] = useState()
  
  const httpLink = createHttpLink({
    uri: CONTENTFUL_URL,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: TOKEN ? `Bearer ${TOKEN}` : '',
      }
    }
  })

  const currentSiteAccess = useContext(SiteAccessContext)

  useEffect(() => {
    // Get apollo client and set it
    let updateClient = async () => {
      setClient(await getApolloClient(
        authLink.concat(httpLink)
      ))
    }
    updateClient()

    // Update favicon and manifest based on domain
    const updateFaviconManifest = async () => {
      const favicon = document.getElementById('favicon')
      const manifest = document.getElementById('manifest')
      const hostname = window.location.origin
      if (currentSiteAccess === 'Kyrgyzstan') {
        favicon.href = `${hostname}/favicon-kyrgyzstan.ico`
        manifest.href = `${hostname}/manifest-kyrgyzstan.json`
      } else {
        favicon.href = `${hostname}/favicon-mongolia.ico`
        manifest.href = `${hostname}/manifest-mongolia.json`
      }
    }
    updateFaviconManifest()
    // eslint-disable-next-line
  }, [])

  const cookies = new Cookies()

  const [currentLocale, setCurrentLocale] = useState(cookies.get('siteLocale') ? cookies.get('siteLocale') : languages[currentSiteAccess][Object.keys(languages[currentSiteAccess])[0]])

  // While client is not set don't show the app / try to render anything
  if(!client){
    return (<></>)
  }

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route exact path='/'>
          <IndexPage
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route exact path='/projects'>
          <ProjectsPage
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route exact path='/learn'>
          <LearnPage
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route path='/more' component={MorePage} />
        <Route path='/about'>
          <AboutPage
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route path='/news/:articleId'>
          <ArticlePage 
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route path='/projects/:contentId'>
          <ProjectPage
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route path='/learn/:articleId'>
          <ArticlePage
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route path='/category/:categoryId'>
          <CategoryPage
            currentLocale = {currentLocale}
            setCurrentLocale = {setCurrentLocale}
          />
        </Route>
        <Route component={NotFoundPage} />
      </Switch>
    </ApolloProvider>
  )
}

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)

// This is set to unregister for local development. When we run in production mode, by calling
// yarn build, this will be enabled by webpack, thus creating our progressive web app, so we
// can just leave this as unregister()
serviceWorker.register()
