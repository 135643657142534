/** @jsx jsx */
import { jsx, Flex, Link } from 'theme-ui'
import PropTypes from "prop-types"
import React, { useContext } from "react"
import Cookies from 'universal-cookie'

import { SiteAccessContext } from './../SiteAccessContext'
import { languages } from '../../config/languages'

const headerLanguageOptionsStyles = {
  flexDirection: 'column',
  position: 'absolute',
  borderRadius: '8px 0px 0px',
  padding: '6px 12px',
  backgroundColor: 'header',
  fontSize: '14px',
  cursor: 'pointer'
}

const HeaderLanguageOptions = ({ setCurrentLanguage, setCurrentLocale }) => {
  const currentSiteAccess = useContext(SiteAccessContext)

  const options = currentSiteAccess === "Mongolia" ? languages.Mongolia : languages.Kyrgyzstan
  const langs = Object.keys(options)

  const cookies = new Cookies()
  
  const handleClick = (e) => {
    e.persist()
    const lang = e.target.innerText
    setCurrentLanguage(lang)
    setCurrentLocale(options[lang])
    cookies.set('siteLocale', options[lang], { path: '/' })
  }
  
  return (
    <React.Fragment>
      <Flex
        as="nav"
        sx={headerLanguageOptionsStyles}
      >
        {langs.map((lang, key) => 
          <Link
            key={key}
            sx={{ textDecoration: 'none' }}
            onClick={handleClick}
          >   
            {lang}
          </Link>
        )}
      </Flex>
    </React.Fragment>
  )
}

HeaderLanguageOptions.propTypes = {
  data: PropTypes.object,
  currentSiteAccess: PropTypes.string,
  setCurrentLanguage: PropTypes.func
}

export default HeaderLanguageOptions