import React, { useContext } from "react"
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import '../styles/fonts.css'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from "../components/layout"
import Container from "../components/container"

const NotFoundPage = () => {
  const currentSiteAccess = useContext(SiteAccessContext)

  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout>
        <Container>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default NotFoundPage
