/** @jsx jsx */
import { jsx, Box, Flex, Text, Image } from 'theme-ui'
// eslint-disable-next-line
import React, { useContext, useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import '../styles/fonts.css'
import translate from '../config/translations.js'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from '../components/layout'
import Container from '../components/container'
import NewsArticleCard from '../components/news/news-article-card'
import UsefulLink from '../components/ui/useful-link'
import ShowMore from '../components/ui/show-more'

import locationPin from '../images/location-pin.svg'

const projectPage = {
  backgroundColor: 'background',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: ['auto 340px', 'auto 680px', 'auto 920px', 'auto 1152px'],
  'a': {
    color: 'secondary'
  },
  'a:hover': {
    textDecoration: 'none'
  }
}

const projectDetailsContainer = {
  paddingTop: ['142px', '136px'],
  flexDirection: ['column', 'row'], 
  alignItems: ['null', 'center'],
  justifyContent: ['null', 'space-between']
}

const productListStyles = {
  marginRight: '16px',
  paddingLeft: 0,
  'li': {
    listStyle: 'none'
  },
  'li::before': {
    content: '\'•\'',
    marginRight: '10px',
    fontSize: '30px',
    lineHeight: 0,
    verticalAlign: 'middle'
  }
}

const projectDescriptionContainer = {
  backgroundColor: ['null', 'header'],
  padding: ['null', '24px 24px 12px 24px'],
  marginTop: ['40px', '30px'],
  borderRadius: ['null', '8px']
}

// eslint-disable-next-line react/prop-types
const ProjectPage = ({ currentLocale, setCurrentLocale }) => {
  let { contentId } = useParams()
  
  const GET_PROJECT_PAGE = gql`
    query GetProjectPageData($offset: Int!, $limit: Int!, $contentId: String!, $currentLocale: String!) {
      project(id: $contentId, locale: $currentLocale) {
        sys {
          id
        }
        name
        coverImage {
          url
        }
        location
        products
        description {
          json
        }
        website
        usefulResources {
          sys {
            id
          }
          title
        }
        linkedFrom {
          articleCollection(locale: $currentLocale, skip: $offset, limit: $limit) {
            total
            items {
              sys {
                id
                publishedAt
              }
              title
              location
              coverImage {
                url
              }
            }
          }
        }
      }
    }
  `

  // Get current siteaccess (Mongolia or Kyrgyzstan)
  const currentSiteAccess = useContext(SiteAccessContext)

  // Limit for data and pagination
  const [limit, setLimit] = useState(2)

  // Get the projects data
  const { data, loading, error, fetchMore } = useQuery(GET_PROJECT_PAGE, {
    variables: { offset: 0, limit, contentId, currentLocale },
    fetchPolicy: 'cache-and-network'
  })
  const [project, setProject] = useState(undefined)
  const [projectArticles, setProjectArticles] = useState(undefined)
  const [fullDescription, setFullDescription] = useState(false)
  const [currentLength, setCurrentLength] = useState(projectArticles ? projectArticles.length : 0)
  const [limitReached, setLimitReached] = useState(true)

  useEffect(() => {
    if (loading === false && data) {
      setProject(data.project)
      setProjectArticles(data.project.linkedFrom.articleCollection.items)
      setCurrentLength(data.project.linkedFrom.articleCollection.items.length)
      setLimitReached(data.project.linkedFrom.articleCollection.items.length < limit
        || data.project.linkedFrom.articleCollection.items.length === data.project.linkedFrom.articleCollection.total)
    }
    if (error) console.error(error)
  }, [data, loading, error, limit])

  const handleShowMore = () => {
    fetchMore({
      variables: {
        offset: currentLength,
        limit: 2
      }
    }).then(fetchMoreResult => {
      let additionalLength = fetchMoreResult.data.project.linkedFrom.articleCollection.items.length
      setLimit(currentLength + additionalLength)
      additionalLength < limit ? setLimitReached(true) : setLimitReached(false)
    })
  }

  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      >
        <Box
          sx={{
            ...projectPage,
            backgroundImage: `linear-gradient(180deg, rgba(0, 22, 39, 0.1) 0%, #001627 60%), url(${project && project.coverImage ? project.coverImage.url : ''})`
          }}
        >
          <Container>
            {project ?
              <Box>
                <Flex sx={projectDetailsContainer}>
                  <Box>
                    <Text
                      as="h1"
                      sx={{
                        fontSize: ['24px', '42px'],
                        fontWeight: 'heading'
                      }}
                    >
                      {project.name}
                    </Text>
                    {project.location ? 
                      <Flex sx={{ alignItems: 'center' }}>
                        <Image
                          src={locationPin}
                          sx={{
                            display: ['none', 'block'],
                            height: '24px',
                            marginRight: '16px'
                          }}
                        />
                        <Text
                          sx={{
                            marginTop: '4px',
                            fontSize: ['16px', '21px'],
                            fontWeight: 'detail',
                            color: 'secondary'
                          }}
                        >
                          {project.location}
                        </Text>
                      </Flex>
                      : ''}
                  </Box>
                  <Flex
                    sx={{ marginTop: ['8px', '0'] }}
                  >
                    {project.products.map((product, key) =>
                      <Flex
                        key={key}
                        as='ul'
                        sx={productListStyles}
                      >
                        <Text
                          as='li'
                          sx={{ 
                            color: product.toLowerCase(),
                            fontSize: ['13px', '16px'],
                            fontWeight: 'bold',
                          }}
                        >
                          { translate(product) }
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                <Box
                  sx={projectDescriptionContainer}
                >
                  <Text
                    as="h2"
                    variant="cardTitle"
                    sx={{ margin: '0 0 8px 0' }}
                  >
                    { translate('description') }
                  </Text>
                  <Text
                    variant="intro"
                    sx={{
                      height: fullDescription ? '100%' : '96px',
                      overflow: 'hidden',
                      'p': {
                        marginTop: '0'
                      }
                    }}
                  >
                    {documentToReactComponents(project.description.json)}
                  </Text>
                  <ShowMore
                    onClick={() => fullDescription ? setFullDescription(false) : setFullDescription(true)}
                  >
                    {fullDescription ? translate('readLess') : translate('readMore') }
                  </ShowMore>
                </Box>
                {
                  project.website ? 
                    (
                      <>
                        <Text
                          as="h2"
                          variant="cardTitle"
                          sx={{
                            margin: ['8px 0 12px 0', '0 0 12px 0'],
                            paddingTop: '24px',
                            borderTop: ['2px solid', 'none'],
                            borderTopColor: 'header'
                          }}
                        >
                          { translate('mineWebsite') }
                        </Text>
                        <UsefulLink
                          url={project.website}
                          title={project.website}
                        />
                        <Text
                          sx={{
                            marginTop: '8px',
                            fontSize: '14px',
                            fontWeight: 'body',
                            color: 'blue5'
                          }}
                        >
                          { translate('mineDisclaimer') }
                        </Text>
                      </>
                    ) : ''
                }
                {projectArticles.length > 0 ? 
                  <Text
                    as="h2"
                    variant="cardTitle"
                    sx={{ margin: ['32px 0 8px 0', '40px 0 8px 0'] }}
                  >
                    {project.name} { translate('news') }
                  </Text>
                  : ''}
                {projectArticles ? projectArticles.map((article, i) => 
                  <NewsArticleCard
                    id={i}
                    key={article.sys.id}
                    contentId={article.sys.id}
                    title={article.title}
                    location={article.location}
                    date={article.sys.publishedAt}
                    image={article.coverImage ? article.coverImage.url : ''}
                  />
                ) : ''}
                {!limitReached ? 
                  <ShowMore
                    onClick={handleShowMore}
                    loading={loading}
                  />
                  : ''}
                { project.usefulResources ?
                  <Text
                    as="h2"
                    variant="cardTitle"
                    sx={{ margin: ['24px 0 12px 0', '24px 0 16px 0'] }}
                  >
                    { translate('usefulLinks') }
                  </Text>
                  : '' }
                { project.usefulResources && project.usefulResources.length > 1 ? project.usefulResources.map(resource =>
                  <UsefulLink
                    key={resource.sys.id}
                    contentId={resource.sys.id}
                    title={resource.title}
                  /> 
                ) : project.usefulResources ?
                  <UsefulLink
                    key={project.usefulResources.sys.id}
                    articleId={project.usefulResources.sys.id}
                    title={project.usefulResources.title}
                  />
                  : ''}
              </Box>
              : ''}
          </Container>
        </Box>
      </Layout>
    </ThemeProvider>
  )
}

export default ProjectPage