import React from 'react'
import { domains } from '../config/siteaccess'

let currentSiteAccess = ""
const url = typeof window !== 'undefined' ? window.location.href : ""

const checkSiteAccess = (domain) => url.includes(domain)

if (currentSiteAccess === "") {
  if (domains.mongolia.some(checkSiteAccess)) {
    currentSiteAccess = "Mongolia"
  }

  if (domains.kyrgyzstan.some(checkSiteAccess)) {
    currentSiteAccess = "Kyrgyzstan"
  } else {
    currentSiteAccess = "Mongolia"
  }
}

export const SiteAccessContext = React.createContext(currentSiteAccess)