/** @jsx jsx */
import { jsx, NavLink, Image, Text } from 'theme-ui'
import PropTypes from "prop-types"

const NavMenuLink = (props) => {
  return (
    <NavLink
      href={props.href}
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        alignItems: 'center',
      }}
    >
      <Image
        src={props.src}
        sx={{ marginRight: [0, '12px'] }}
        variant='nav'
      />
      <Text
        sx={{
          marginTop: ['4px', 0],
          marginRight: [0, '40px']
        }}
        variant={props.textVar}
      >
        {props.text}
      </Text>
    </NavLink>
  )
}

NavMenuLink.propTypes = {
  href: PropTypes.string,
  src: PropTypes.string,
  text: PropTypes.string,
  textVar: PropTypes.string,
}

export default NavMenuLink