import { ApolloClient, InMemoryCache } from '@apollo/client'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'

/**
 * Schema version (increment this when the GQL schema majorly changes to purge caches on deploy)
 */
const SCHEMA_VERSION = '1'
const SCHEMA_VERSION_KEY = 'apollo-schema-version'

/**
 * Retrieves a authenticated apollo client
 * @param authLink 
 */
const getApolloClient = async (httpLink) => {
  const cache = new InMemoryCache()

  const persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  })

  const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY)

  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore()
  } else {
    await persistor.purge()
    window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  return await new ApolloClient({ link: httpLink, cache })
}

export default getApolloClient