/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { useContext, useState, useEffect } from "react"
import { useQuery, gql } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import '../styles/fonts.css'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from "../components/layout"
import Container from "../components/container"
import NewsArticle from "../components/news/news-article"

const aboutPage = {
  backgroundColor: 'background',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 280px'
}

const aboutIds = {
  "Kyrgyzstan": process.env.REACT_APP_ABOUT_ID_KG,
  "Mongolia": process.env.REACT_APP_ABOUT_ID_MN
}

const AboutPage = ({ currentLocale, setCurrentLocale }) => {

  const GET_ARTICLE = gql`
    query GetArticleData($aboutId: String!, $currentLocale: String!) {
      article(id: $aboutId, locale: $currentLocale){
        sys {
          id
          publishedAt
        }
        coverImage {
          url
        }
        title
        location
        intro {
          json
        }
        body {
          json
        }
      }
    }
  `

  const currentSiteAccess = useContext(SiteAccessContext)

  const aboutId = aboutIds[currentSiteAccess]

  const { data, loading, error } = useQuery(GET_ARTICLE, {
    variables: { currentLocale, aboutId },
    fetchPolicy: 'cache-and-network' 
  })

  const [article, setArticle] = useState(null)

  useEffect(() => {
    if (loading === false && data?.article) {
      setArticle(data.article)
    }
    if (error) console.error(error)
  }, [data, loading, error])

  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout        
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      >
        <Box
          sx={{
            ...aboutPage,
            backgroundImage: `linear-gradient(180deg, rgba(0, 22, 39, 0) 0%, #001627 90%), url(${article && article.coverImage ? article.coverImage.uri : ''})`
          }}
        >
          <Container>
            {article ?
              <NewsArticle
                id={article.sys.id}
                title={article.title}
                location={article.location}
                date={article.sys.publishedAt}
                intro={article.intro ? article.intro.json : ''}
                body={article.body ? article.body.json : ''}
              />
            : ''}
          </Container>
        </Box>
      </Layout>
    </ThemeProvider>
  )
}

export default AboutPage
