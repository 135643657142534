/** @jsx jsx */
import { jsx, Flex, NavLink, Text, Image } from 'theme-ui'
import arrowRight from "../../images/arrow-right.svg"
import translate from "../../config/translations.js"

const articleCard = {
  width: '100%',
  marginBottom: '12px',
  padding: ['16px', '16px 24px'],
  boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  backgroundColor: 'header',
  ':hover': {
    backgroundColor: 'blockMouseover'
  }
}

const ArticleCard = (props) => {
  const months = translate('months')
  let date = new Date(props.article.sys.publishedAt)
  date = `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`

  return (
    <NavLink
      href={`/learn/${props.article.sys.id}`}
      sx={articleCard}
    >
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Flex sx={{ flexDirection: 'column' }}>
          <Text
            as="h4"
            variant="cardTitleSmall"
            sx={{
              maxWidth: ['260px', '600px', '800px'],
              fontSize: ['null', '21px'],
              lineHeight: ['22px', '27px']
            }}
          >
            {props.article.title}
          </Text>
          <Text
            as="p"
            variant="cardDetailsSmall"
            sx={{ marginTop: '4px' }}
          >

            {`Updated ${date}`}
          </Text>
        </Flex>
        <Image
          src={arrowRight}
          sx={{ height: '16px' }}
        />
      </Flex>
    </NavLink>
  )
}

export default ArticleCard