/** @jsx jsx */
import { jsx, Flex, Text, Image, NavLink, Box } from 'theme-ui'
import PropTypes from "prop-types"
import { useContext } from "react"

import { SiteAccessContext } from './../SiteAccessContext'

import HeaderNavMenu from "./header-nav-menu"
import HeaderLanguageSelector from "./header-language-selector"

import mongoliaLogo from "../../images/app-logo-mongolia.svg"
import kyrgystanLogo from "../../images/app-logo-kyrgystan.svg"

const headerStyles = {
  height: '54px',
  padding: '9px 16px',
  borderTop: '2px solid',
  borderColor: 'headerBorder',
  backgroundColor: 'header',
  fontSize: '16px',
  'a:hover': {
    color: 'text'
  }
}

const Header = ({ siteTitle, currentLocale, setCurrentLocale }) => {
  const currentSiteAccess = useContext(SiteAccessContext)

  return (
    <Box
      as="header"
      sx={headerStyles}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: ['340px', '680px', '920px', '1152px'],
          margin: '0 auto'
        }}
      >
        <Flex>
          <NavLink
            href="/"
            sx={{ marginRight: '56px' }}
          >
            <Flex
              sx={{
                height: '36px',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Image
                src={currentSiteAccess === `Kyrgyzstan` ? kyrgystanLogo : mongoliaLogo}
                sx={{ height: '36px' }}
              />
              <Text
                sx={{
                  marginLeft: '8px'
                }}>
                {siteTitle}
              </Text>
            </Flex>
          </NavLink>
          <HeaderNavMenu />
        </Flex>
        {currentSiteAccess === `Kyrgyzstan` ?
          <HeaderLanguageSelector
            currentLocale={currentLocale}
            setCurrentLocale={setCurrentLocale}
          /> : ''
        }
      </Flex>
    </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  currentLocale: PropTypes.string,
  setCurrentLocale: PropTypes.func
}

export default Header