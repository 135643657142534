import React, { useContext } from "react"
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import '../styles/fonts.css'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from "../components/layout"
import Container from "../components/container"

// language specific static pages to be included in another ticket
const MorePage = () => {
  const currentSiteAccess = useContext(SiteAccessContext)
  
  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout>
        <Container>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default MorePage
