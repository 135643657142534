/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { useContext } from 'react'

import { SiteAccessContext } from '../SiteAccessContext'

import NavMenuLink from "../nav/nav-menu-link"

import newsIcon from "../../images/nav-news-icon.svg"
import activeNewsIconMongolia from "../../images/nav-news-icon-active-m.svg"
import activeNewsIconKyrgyzstan from "../../images/nav-news-icon-active-k.svg"
import projectsIcon from "../../images/nav-projects-icon.svg"
import activeProjectsIconMongolia from "../../images/nav-projects-icon-active-m.svg"
import activeProjectsIconKyrgyzstan from "../../images/nav-projects-icon-active-k.svg"
import learnIcon from "../../images/nav-learn-icon.svg"
import activeLearnIconMongolia from "../../images/nav-learn-icon-active-m.svg"
import activeLearnIconKyrgyzstan from "../../images/nav-learn-icon-active-k.svg"
// import moreIcon from "../../images/nav-more-icon.svg"
// import activeMoreIcon from "../../images/nav-more-icon-active.svg"
import translate from "../../config/translations.js"

const headerNavMenuStyles = {
  flexDirection: 'row',
}

const HeaderNavMenu = () => {
  const currentSiteAccess = useContext(SiteAccessContext)
  const pathname = typeof window !== `undefined` ? window.location.pathname : ``

  return (
    <Flex
      as="nav"
      sx={{ ...headerNavMenuStyles, display: ['none', 'flex'] }}
    >
      <NavMenuLink
        href="/"
        src={pathname === '/' && currentSiteAccess === "Mongolia" ? activeNewsIconMongolia
        : pathname === '/' && currentSiteAccess === "Kyrgyzstan" ? activeNewsIconKyrgyzstan : newsIcon}
        text={ translate('news') }
        textVar={pathname === '/' ? 'activeNav' : 'nav'}
      />
      <NavMenuLink
        href="/projects"
        src={pathname.includes('projects') && currentSiteAccess === "Mongolia" ? activeProjectsIconMongolia
        : pathname.includes('projects') && currentSiteAccess === "Kyrgyzstan" ? activeProjectsIconKyrgyzstan : projectsIcon}
        text={ translate('projects') }
        textVar={pathname.includes('projects') ? 'activeNav' : 'nav'}
      />
      <NavMenuLink
        href="/learn"
        src={pathname.includes('learn') && currentSiteAccess === "Mongolia" ? activeLearnIconMongolia
        : pathname.includes('learn') && currentSiteAccess === "Kyrgyzstan" ? activeLearnIconKyrgyzstan : learnIcon}
        text={ translate('learn') }
        textVar={pathname.includes('learn') ? 'activeNav' : 'nav'}
      />
      {/* <NavMenuLink
        href="/more"
        src={pathname.includes('more') ? activeMoreIcon : moreIcon}
        text={ translate('more') }
        textVar={pathname.includes('more') ? 'activeNav' : 'nav'}
      /> */}
    </Flex>
  )
}

export default HeaderNavMenu