/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

const Dot = ({ active }) => {
  const dotStyles = {
    height: '8px',
    width: '8px',
    backgroundColor: active ? 'royalBlue' : 'dullerBlue',
    borderRadius: '100px',
    marginX: '6px',
    boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.3)'
  }
  
  return (
    <Box
      sx={dotStyles}
    />
  )
}

export default Dot