/** @jsx jsx */
import { jsx, NavLink, Flex, Text, Image } from 'theme-ui'
import PropTypes from "prop-types"
import React from "react"
import translate from "../../config/translations.js"

import arrowRight from "../../images/arrow-right.svg"
import locationIcon from "../../images/user-icon.svg"
import dateIcon from "../../images/calendar-icon.svg"

const NewsArticleCard = (props) => {
  const firstCard = props.id === 0
  const even = props.id%2 === 0
  const indexPage = window.location.pathname === '/'

  const newsArticleCard = {
    borderRadius: '8px',
    padding: ['16px', firstCard && indexPage ? '32px' : '40px 26px 54px 30px'],
    margin: ['8px 0', firstCard && indexPage ? '32px 0 12px 0' : '12px 0'],
    backgroundColor: 'primary',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `linear-gradient(180deg, rgba(0, 22, 39, 0) 0%, rgba(0, 22, 39, 0.55) 0%), url(${props.image})`,
    width: ['100%', firstCard && indexPage ? '100%' : 'calc(50% - 12px)'],
    marginRight: [0, !even && indexPage ? '24px' : even && !indexPage && '24px'],
    boxShadow: '0px 4px 24px 2px rgba(0, 0, 0, 0.4)'
  }

  const newsArticleTitle = { 
    maxHeight: ['100%', (!firstCard || !indexPage) && '62px'],
    overflow: 'hidden',
    flex: 10,
    fontSize: ['null', firstCard && indexPage && '28px']
  }

  const months = translate('months')
  let date = new Date(props.date)
  date = `${months[date.getMonth()]} ${date.getDate()}`

  return (
    <React.Fragment>
      <NavLink
        href={`/news/${props.contentId}`}
        sx={newsArticleCard}
      >
        <Flex 
          sx={{
            flexDirection: ['column'],
            height: ['null', (!firstCard || !indexPage) && '118px']
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Text
              as="h2"
              variant="cardTitle"
              sx={newsArticleTitle}
            >
              {props.title}
            </Text>
            <Image
              src={arrowRight}
              sx={{
                flex: [0.6, firstCard && indexPage ? 0.7 : 0.8, firstCard && indexPage ? 0.5 : 0.45],
                marginLeft: ['30px', firstCard && indexPage && '200px', firstCard && indexPage && '300px', firstCard && indexPage && '400px']
              }}
            />
          </Flex>
          <Flex
            sx={{ flexDirection: ['column', firstCard && indexPage && 'row'] }}
          >
            {props.location &&
              <Flex
                sx={{
                  alignItems: 'center',
                  marginTop: '10px',
                  marginRight: [0, firstCard && indexPage && '24px']
                }}
              >
                <Image
                  src={locationIcon}
                />
                <Text
                  variant="cardDetails"
                  sx={{
                    marginLeft: '12px',
                    fontSize: ['null', firstCard && indexPage && '16px']
                  }}
                >
                  {props.location}
                </Text>
              </Flex>
            }
            <Flex
              sx={{
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Image
                src={dateIcon}
              />
              <Text
                variant="cardDetails"
                sx={{
                  marginLeft: '12px',
                  fontSize: ['null', firstCard && indexPage && '16px']
                }}
              >
                {date}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </NavLink>
    </React.Fragment>
  )
}

NewsArticleCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string
}

export default NewsArticleCard