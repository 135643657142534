/** @jsx jsx */
import { jsx, Flex, Image, Text, Box } from 'theme-ui'
import PropTypes from "prop-types"
import React, { useState, useContext, useRef, useEffect } from "react"

import { SiteAccessContext } from './../SiteAccessContext'
import { languages } from '../../config/languages'

import HeaderLanguageOptions from "./header-language-options"

import arrowDown from '../../images/arrow-menu-down.svg'

const headerLanguageSelectorStyles = {
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '32px',
  boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.6)',
  borderRadius: '8px',
  padding: '8px 12px',
  backgroundColor: 'header',
  color: 'secondary',
  fontSize: '14px',
  cursor: 'pointer'
}

const HeaderLanguageSelector = ({ currentLocale, setCurrentLocale }) => {
  const currentSiteAccess = useContext(SiteAccessContext)

  const [currentLanguage, setCurrentLanguage] = useState(Object.keys(languages[currentSiteAccess]).find(key => languages[currentSiteAccess][key] === currentLocale))
  const [selectorOpen, setSelectorOpen] = useState(false)
  const menuRef = useRef(null);

  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setSelectorOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    }
  })

  return (
    <React.Fragment>
      <Flex
        sx={{
          flexDirection: 'column'
        }}
        onClick={() => setSelectorOpen(!selectorOpen)}
      >
        <Flex
          sx={headerLanguageSelectorStyles}>
          <Text>{currentLanguage}</Text>
          <Image
            src={arrowDown}
            sx={{
              marginLeft: '12px'
            }}
          />
        </Flex>
        <Box
          ref={menuRef}>
          {selectorOpen ?
          <HeaderLanguageOptions
            setCurrentLocale={setCurrentLocale}
            setCurrentLanguage={setCurrentLanguage}
          /> : ""}
        </Box>
      </Flex>
    </React.Fragment>
  )
}

HeaderLanguageSelector.propTypes = {
  data: PropTypes.object,
}

export default HeaderLanguageSelector