/** @jsx jsx */
import { jsx, NavLink, Flex, Text, Image } from 'theme-ui'
import PropTypes from "prop-types"
import React from "react"
import translate from '../../config/translations.js'

import arrowRight from "../../images/arrow-right.svg"

const projectCard = {
  display: 'flex',
  flexDirection: ['column', 'row'],
  justifyContent: ['null', 'space-between'],
  alignItems: ['null', 'center'],
  borderRadius: '8px',
  width: '100%',
  padding: ['16px', '16px 34px 16px 24px'],
  margin: ['4px 0 12px 0', '0 0 12px 0'],
  backgroundColor: 'primary',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  boxShadow: '0px 4px 24px 2px rgba(0, 0, 0, 0.4)'
}

const ProjectCard = (props) => {
  return (
    <React.Fragment>
      <NavLink
        href={`/projects/${props.contentId}`}
        sx={{
          ...projectCard,
          backgroundImage: `linear-gradient(180deg, rgba(31, 53, 69, 0) 20.18%, rgba(31, 53, 69, 0.9) 100%), url(${props.coverImage})`
        }}
      >
        <Flex sx={{ flexDirection: 'column' }}>
          <Flex sx={{ alignItems: 'center' }}>
            <Text
              as="h2"
              variant="cardTitle"
              sx={{ flex: 10 }}
            >
              {props.title}
            </Text>
            <Image
              src={arrowRight}
              sx={{
                display: ['block', 'none'],
                height: '16px',
                marginLeft: '30px'
              }}
            />
          </Flex>
          <Flex
            sx={{
              alignItems: 'center',
              marginTop: '6px'
            }}
          >
            <Text
              variant="cardDetails"
            >
              {props.location}
            </Text>
          </Flex>
        </Flex>
        <Flex sx={{ alignItems: 'center' }}>
          <Flex
            sx={{ marginTop: ['8px', '0'] }}
          >
            {props.products.map((product, i) =>
              <Flex
                key={i}
                as='ul'
                sx={{
                  marginRight: '16px',
                  paddingLeft: 0,
                  'li': {
                    listStyle: 'none'
                  },
                  'li::before': {
                    content: `'•'`,
                    marginRight: '10px',
                    fontSize: '30px',
                    lineHeight: 0,
                    verticalAlign: 'middle'
                  }
                }}
              >
                <Text
                  as='li'
                  sx={{ 
                    color: product.toLowerCase(),
                    fontSize: '13px',
                    fontWeight: 'bold',
                  }}
                >
                  { translate(product) }
                </Text>
              </Flex>
            )}
          </Flex>
          <Image
            src={arrowRight}
            sx={{
              display: ['none', 'block'],
              height: '16px',
              marginLeft: '30px'
            }}
          />
        </Flex>
      </NavLink>
    </React.Fragment>
  )
}

ProjectCard.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  products: PropTypes.array,
  coverImage: PropTypes.string
}

export default ProjectCard