/** @jsx jsx */
import { jsx, NavLink, Image, Text } from 'theme-ui'

import projectsCtaIcon from '../../images/projects-cta-icon.svg'
import arrowRight from "../../images/arrow-right-cta.svg"

const ctaStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 12px',
  width: '360px',
  textAlign: 'center',
  backgroundColor: 'none'
}

const ProjectsCta = ({ title, description }) => {
  return (
    <NavLink
      href='/projects'
      sx={ctaStyles}
    >
      <Image 
        sx={{ width: '76px' }}
        src={projectsCtaIcon}
      />
      <Text
        as='h2'
        variant='cardTitle'
        sx={{ marginTop: '12px' }}
      >
        {title}
      </Text>
      <Text
        sx={{
          marginTop: '8px',
          fontSize: '16px',
          fontWeight: 'body',
          color: 'text'
        }}
      >
        {description}
      </Text>
      <Image
        sx={{
          marginTop: '16px',
          padding: '12px',
          background: 'rgba(31, 53, 69, 0.6)',
          borderRadius: '100px',
        }}
        src={arrowRight}
      />
    </NavLink>
  )
}

export default ProjectsCta