import React, { useContext, useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import ReactPaginate from 'react-paginate'
import { projectProducts, projectLocations } from '../config/project-filters'
import '../styles/fonts.css'
import '../styles/pagination.scss'
import translate from '../config/translations.js'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from '../components/layout'
import Container from '../components/container'
import Heading from '../components/heading'
import ProjectFilterSelector from '../components/project/project-filter-selector'
import ProjectFilterResultCount from '../components/project/project-filter-result-count'
import ProjectCard from '../components/project/project-card'

// eslint-disable-next-line no-undef
let _ = require('lodash')

// eslint-disable-next-line react/prop-types
const ProjectsPage = ({ currentLocale, setCurrentLocale }) => {
  // Query to get Projects category ID
  const GET_PAGES = gql`
    query GetPages($currentSiteAccess: String!) {
      pageCollection(where: {
        site: {
          title: $currentSiteAccess
        }
        title: "Projects"
      }) {
        items {
          title
          componentsCollection {
            items {
              ... on ComponentsCategory {
                sys {
                  id
                }
                name
              }
            }
          }
        }
      }
    }
  `

  const GET_PROJECTS = gql`
    query GetProjects($pageLimit: Int!, $pageOffset: Int!, $projectsCategoryId: String!, $currentLocale: String!, $productFilters: [String], $locationFilters: [String]) {
      projectCollection(locale: $currentLocale, limit: $pageLimit, skip: $pageOffset, where: {
        category: {
          sys: {
            id: $projectsCategoryId
          }
        },
        OR: [
          { products_contains_some: $productFilters },
          { region_province_in: $locationFilters }
        ],
      }) {
        items {
          sys {
            id
          }
          name
          products
          regionProvince
          location
          coverImage {
            url
          }
        }
      }
    }
  `

  const GET_ALL_PROJECTS = gql`
    query GetAllProjects($projectsCategoryId: String!, $currentLocale: String!) {
      projectCollection(locale: $currentLocale, where: {
        category: {
          sys: {
            id: $projectsCategoryId
          }
        }
      }) {
        total
        items {
          sys {
            id
          }
          name
          products
          regionProvince
          location
          coverImage {
            url
          }
        }
      }
    }
  `

  // Get current siteaccess
  const currentSiteAccess = useContext(SiteAccessContext)

  let predefinedProducts = _.cloneDeep(projectProducts)
  let predefinedLocations = _.cloneDeep(projectLocations[currentSiteAccess][currentLocale])

  const [projectsCategoryId, setProjectsCategoryId] = useState('')
  const [projects, setProjects] = useState(undefined)
  const [allProjects, setAllProjects] = useState(undefined)

  // States for selected filters
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedLocations, setSelectedLocations] = useState([])

  // States for all location and product filters
  const [productFilters, setProductFilters] = useState([])
  const [locationFilters, setLocationFilters] = useState([])

  // Are filters set?
  const [filtersSet, setFiltersSet] = useState(false)

  // Page offset for numbered pagination
  const [pageOffset, setPageOffset] = useState(0)

  // Results per page
  const pageLimit = 10

  // Data from GraphQL queries
  const { data: pagesResult, loading: pagesQueryLoading, pagesQueryError } = useQuery(GET_PAGES, {
    variables: { currentSiteAccess },
    fetchPolicy: 'cache-and-network'
  })
  const { data: projectsResult, loading: projectsQueryLoading, projectsQueryError, fetchMore: fetchMoreProjects } = useQuery(GET_PROJECTS, {
    variables: { pageOffset, pageLimit, projectsCategoryId, currentLocale, productFilters, locationFilters },
    fetchPolicy: 'cache-and-network'
  })
  const { data: allProjectsResult, loading: allProjectsQueryLoading, allProjectsQueryError, fetchMore: fetchMoreAllProjects } = useQuery(GET_ALL_PROJECTS, {
    variables: { projectsCategoryId, currentLocale },
    fetchPolicy: 'cache-and-network'
  })

  if (allProjectsResult) {
    const predefinedLocationsKeys = Object.keys(predefinedLocations)

    allProjectsResult.projectCollection.items.forEach(project => {
      // Increment province/region count
      if (project.regionProvince && predefinedLocationsKeys.includes(project.regionProvince)) {
        predefinedLocations[project.regionProvince].push(project.name)
      }

      // Count results per product
      project.products.forEach(product => {
        predefinedProducts[product].push(project.name)
      })
    })
  }

  useEffect(() => {
    if (pagesQueryLoading === false && pagesResult && pagesResult.pageCollection.items[0].componentsCollection.items[0]) {
      setProjectsCategoryId(pagesResult.pageCollection.items[0].componentsCollection.items[0].sys.id)
    }

    if (projectsQueryLoading === false && projectsResult) {
      setProjects(projectsResult.projectCollection.items[0] ? projectsResult.projectCollection.items : [])
    }

    if (allProjectsQueryLoading === false && allProjectsResult) {
      setAllProjects(allProjectsResult.projectCollection.items[0] ? allProjectsResult.projectCollection.items : [])
    }

    if (pagesQueryError) console.error(pagesQueryError)
    if (projectsQueryError) console.error(projectsQueryError)
    if (allProjectsQueryError) console.error(allProjectsQueryError)

  }, [pagesResult, pagesQueryLoading, projectsResult, projectsQueryLoading, allProjectsResult, allProjectsQueryLoading, pagesQueryError, projectsQueryError, allProjectsQueryError])

  const resetFilters = () => {
    setProductFilters([])
    setLocationFilters([])
    setSelectedProducts([])
    setSelectedLocations([])
    setFiltersSet(false)
  }

  const pageCount = allProjectsResult ? Math.floor((allProjectsResult.projectCollection.total + pageLimit - 1) / pageLimit) : ''

  const handlePageChange = (page) => {
    setPageOffset(page.selected * pageLimit)
    fetchMoreProjects({
      variables: {
        pageOffset
      }
    })
    fetchMoreAllProjects({
      variables: {
        pageOffset
      }
    })
  }
  
  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      >
        <Container>
          <Heading
            pageTitle={ translate('projects') }
            projectsTotal={allProjectsResult && allProjectsResult.projectCollection.total}
          >
            <ProjectFilterSelector
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              resetFilters={resetFilters}
              setProductFilters={setProductFilters}
              setLocationFilters={setLocationFilters}
              predefinedProducts={predefinedProducts}
              predefinedLocations={predefinedLocations}
              setFiltersSet={setFiltersSet}
            />
          </Heading>
          <ProjectFilterResultCount
            projects={projects}
            allProjects={allProjects}
            resetFilters={resetFilters}
            filtersSet={filtersSet}
            productFilters={productFilters}
            locationFilters={locationFilters}
          />
          {(locationFilters.length === 0 && productFilters.length === 0) && allProjects ? allProjects.map((project) =>
            <ProjectCard
              key={project.sys.id}
              contentId={project.sys.id}
              title={project.name}
              location={project.location}
              products={project.products}
              coverImage={project.coverImage && project.coverImage.url}
            />
          ) : (locationFilters.length !== 0 || productFilters.length !==0) && projects ? projects.map((project) =>
            <ProjectCard
              key={project.sys.id}
              contentId={project.sys.id}
              title={project.name}
              location={project.location}
              products={project.products}
              coverImage={project.coverImage && project.coverImage.url}
            />
          ) : ''
          }
          {pageCount > 1 ?
            (
              <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                pageClassName={'pagination__pagination-button'}
                previousClassName={'pagination__pagination-button--previous'}
                nextClassName={'pagination__pagination-button--next'}
                breakClassName={'pagination__pagination-link--break'}
                pageLinkClassName={'pagination__pagination-link'}
                previousLinkClassName={'pagination__pagination-link'}
                nextLinkClassName={'pagination__pagination-link'}
                activeLinkClassName={'pagination__pagination-link'}
                activeClassName={'pagination__pagination-button--active'}
              />
            ) : '' 
          }
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default ProjectsPage