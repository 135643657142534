import { NavLink} from 'theme-ui'
import React from 'react'

const footerLinkStyles = {
  color: 'secondary',
  textDecoration: 'underline',
  ':visited': {
    color: 'secondary',
  }
}
  
const contentfulLink = (
  <NavLink
    href="https://www.contentful.com/"
    target="_blank"
    sx={footerLinkStyles}
  >
    Contentful
  </NavLink>
)

export default contentfulLink