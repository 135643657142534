module.exports = {
  'projectProducts': {
    'Arsenic': [],
    'Copper': [],
    'Coal': [],
    'Cyanide': [],
    'Flurospar': [],
    'Gold': [],
    'Iron': []
  },
  'projectLocations': {
    'Mongolia': {
      'en-US': {
        'Arkhangai': [],
        'Bayan-Olgii': [],
        'Bayankhongor': [],
        'Bulgan': [],
        'Darkhan-Uul': [],
        'Dornod': [],
        'Dornogovi': [],
        'Dundgovi': [],
        'Govi-Altai': [],
        'Govisumber': [],
        'Khentii': [],
        'Khovd': [],
        'Khovsgol': [],
        'Omnogovi': [],
        'Orkhon': [],
        'Ovorkhangai': [],
        'Selenge': [],
        'Sukhbaatar': [],
        'Tov': [],
        'Uvs': [],
        'Zavkhan': []
      },
      'mn-MNG': {
        'Архангай аймаг': [],
        'Баян-Өлгий': [],
        'Баянхонгор аймаг': [],
        'Булган': [],
        'Дархан-Уул': [],
        'Дорнод': [],
        'Дорноговь': [],
        'Дундговь': [],
        'Говь-Алтай': [],
        'Говьсүмбэр': [],
        'Хэнтай': [],
        'Ховд': [],
        'Хөвсгөл': [],
        'Өмнөговь': [],
        'Орхон аймаг': [],
        'Өвөрхангай': [],
        'Сэлэнгэ': [],
        'Сүхбаатар': [],
        'Төв': [],
        'Увс аймаг': [],
        'Завхан': []
      }
    },
    'Kyrgyzstan': {
      'ky-KG': {
        'Баткен облусу': [],
        'Чүй облусу': [],
        'Жалалабат облусу': [],
        'Нарын облусу': [],
        'Ош облусу': [],
        'Талас облусу': [],
        'Ысык-Көл облусу': []
      },
      'ru-RU': {
        'Баткен область': [],
        'Чу ý область': [],
        'Джалал-Абад область': [],
        'Нарын область': [],
        'Ош область': [],
        'Талас область': [],
        'Иссык-Куль область': [],
      },
      'en-US': {
        'Batken': [],
        'Chuy': [],
        'Jalal-Abad': [],
        'Naryn': [],
        'Osh': [],
        'Talas': [],
        'Issyk-Kul': []
      }
    }
  }
}