/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

const iframeContainerStyles = {
  paddingBottom: '56.25%',
  position: 'relative',
  display: 'block',
  width: '100%',
  '> iframe': {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  }
}

const IframeContainer = ({ children }) => {
  return (
    <Box sx={iframeContainerStyles}>
      {children}
    </Box>
  )
}

export default IframeContainer