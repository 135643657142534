/** @jsx jsx */
import { jsx, Flex, Text} from 'theme-ui'

import translate from '../config/translations'

const footerContainerStyles = {
  flexDirection: 'column',
  marginTop: '64px',
  borderTop: '2px solid',
  borderColor: 'dullerBlue'
}

const Footer = () => {
  return (
    <Flex
      sx={footerContainerStyles}
    >
      <Text
        variant='footer'
        sx={{
          marginTop: '32px',
          fontWeight: 'detail'
        }}
      >
        { translate('footerCopyright') }
      </Text>
      <Text
        variant='footer'
        sx={{ marginTop: '12px' }}
      >
        { translate('footerPoweredBy') }{'\u00A0'}
      </Text>
    </Flex>
  )
}

export default Footer